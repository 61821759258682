import { useMemo } from 'react';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './price-display.module.scss';

type Props = {
  isSalePrice?: boolean;
  price: number | string;
  className?: string;
  discounted?: boolean;
  size?: 'big' | 'normal' | 'small';
};

export default function PriceDisplay({
  price,
  className,
  discounted,
  size,
  isSalePrice,
}: Props) {
  const [euros, cents] = useMemo(() => {
    if (typeof price === 'string') {
      const normalizedPrice = price.replace(',', '.').split('.');
      // If a price is an integer, add '00' as cents
      return normalizedPrice.length === 1
        ? [normalizedPrice[0], '00']
        : normalizedPrice;
    }
    return price.toFixed(2).split('.');
  }, [price]);

  return (
    <span
      className={cx(
        $.wrapper,
        className,
        discounted && $.isDiscounted,
        isSalePrice && $.isSalePrice,
        size === 'small' && $.isSmall,
        size === 'big' && $.isBig,
      )}
      aria-label={discounted ? undefined : `€ ${euros}.${cents}`}
      aria-hidden={discounted ? 'true' : undefined}
    >
      <span>{euros}</span>
      <span>,</span>
      {cents === '00' ? (
        <span>-</span>
      ) : (
        <span className={$.cents}>{cents}</span>
      )}
    </span>
  );
}
